import clsx from "clsx"

import createStyle from "@/utils/createStyle"

export const themes = {
  light: "bg-grey-400 hover:bg-grey-0 text-grey-900",
  transparent:
    " bg-transparent hover:bg-grey-0 text-current hover:text-grey-900 border border-current",
  energy: "bg-grey-100 hover:bg-grey-200 text-grey-900 !text-[0.875rem]", // Energy probably needs to be refactored to be a custom size
  dark: "bg-transparent hover:bg-grey-800 text-grey-0 border",
  black: "bg-grey-900 text-grey-0 hover:bg-grey-800",
  grey: "bg-grey-400 text-grey-900 hover:bg-grey-500",
  red: "bg-red-700 text-grey-0 hover:bg-red-600",
  blackText: "text-grey-0 hover:text-grey-600",
  simpleWhite: "bg-grey-0 border border-grey-700 text-grey-900 rounded-full",
  white: "bg-grey-0 text-grey-900",
  purple: "bg-violet-700 text-white",
  custom: "",
}

export const sizes = {
  custom: "",
  large: "py-4 px-7",
  small: "py-2 px-2.5",
}

export const ButtonBase = createStyle(
  "a",
  clsx(
    "font-mono text-[0.75rem] uppercase leading-[1.2] tracking-tightest",
    "inline-flex select-none items-center justify-center gap-2 text-center",
    "group cursor-pointer",
    "rounded",
    "transition duration-200",
    "disabled:pointer-events-none disabled:opacity-50"
  )
)
