"use client"

import { FC, useState } from "react"
import NextImage, { ImageProps } from "next/image"
import clsx from "clsx"

export const Image: FC<
  ImageProps & { autoHeight?: boolean; noAnimate?: boolean }
> = ({
  className = "",
  autoHeight = false,
  noAnimate = false,
  style = {},
  onLoad = () => {},
  ...props
}) => {
  const [isLoaded, setIsLoaded] = useState(false)

  if (!props.src) {
    return (
      <div
        className={clsx(className, "appearance-none border-none")}
        {...props}
      />
    )
  }

  return (
    <NextImage
      className={clsx(
        className,
        !isLoaded && !noAnimate && "opacity-0",
        !(className || "")?.includes("object-contain") && "object-cover",
        !noAnimate && "transition duration-500"
      )}
      // TODO: Refactor to `onLoad` for next@14 forwards compat
      onLoad={(e) => {
        onLoad(e)
        setIsLoaded(true)
      }}
      style={
        props.width &&
        !autoHeight &&
        props.height &&
        !/(w-\[?\d]+)|(h-\[?\d+)/.test(className)
          ? {
              width: Number(props.width),
              height: Number(props.height),
              ...style,
            }
          : style
      }
      {...props}
    />
  )
}
export default Image
