/* eslint-disable react/require-default-props */
import {
  AllHTMLAttributes,
  ForwardRefExoticComponent,
  RefAttributes,
  createElement,
  forwardRef,
} from "react"
import clsx from "clsx"

type ElementType =
  | HTMLDivElement
  | HTMLAnchorElement
  | HTMLFormElement
  | HTMLButtonElement
  | HTMLDListElement
  | HTMLDataElement
  | HTMLElement

export type CustomTagArgs<T = ElementType> = {
  as?: keyof JSX.IntrinsicElements
  inert?: string | null
} & AllHTMLAttributes<T>

export type CustomTag<T = ElementType> = {
  classNames?: string
} & ForwardRefExoticComponent<CustomTagArgs<T> & RefAttributes<T>>

/**
 * Creates a re-usable element with pre-set classnames
 * Great for building generic, pure, Tailwind components
 */
export default function createStyle<T = ElementType>(
  defaultTag: keyof JSX.IntrinsicElements,
  classes = "",
  defaultProps: { displayName?: string } & AllHTMLAttributes<T> = {}
) {
  const { displayName = null, style = {}, ...tagProps } = defaultProps
  const customStyledTag: CustomTag<T> = forwardRef<T, CustomTagArgs<T>>(
    ({ as, className = "", children, ...props }, ref) =>
      createElement(
        as || defaultTag,
        {
          className: clsx(classes, className),
          ref,
          ...tagProps,
          ...props,
          style: { ...style, ...props.style },
        },
        children
      )
  )

  customStyledTag.displayName = displayName || `*${defaultTag}`
  customStyledTag.classNames = classes

  return customStyledTag
}
