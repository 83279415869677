"use client"

import clsx from "clsx"
import { forwardRef } from "react"
import Link from "next/link"

import { ButtonBase, sizes, themes } from "./styles"
import { ButtonProps } from "./types"

const ButtonEl = forwardRef<HTMLAnchorElement, ButtonProps>(
  (
    {
      className = "",
      label,
      size = "large",
      theme = "transparent",
      children,
      ...props
    },
    ref
  ) => {
    return (
      <ButtonBase
        {...props}
        className={clsx(themes[theme], sizes[size], className)}
        ref={ref}
      >
        {label || children}
      </ButtonBase>
    )
  }
)

const Button = forwardRef<HTMLAnchorElement, ButtonProps>(
  ({ href, nativeLink = false, scroll = true, ...props }, ref) => {
    if (!nativeLink && href?.slice(0, 1) === "/") {
      return (
        <Link href={href || ""} passHref legacyBehavior scroll={scroll}>
          <ButtonEl ref={ref} {...props} />
        </Link>
      )
    }

    if (href?.slice(0, 1) === "#" && href.length > 1) {
      return (
        <ButtonEl
          ref={ref}
          href={href}
          onClick={(e) => {
            e.preventDefault()

            const scrollPos =
              document.querySelector<HTMLElement>(href)?.offsetTop

            // Scroll to anchor
            window.scrollTo({ top: scrollPos, behavior: "smooth" })
          }}
          {...props}
        />
      )
    }

    // External links open in new tab
    return <ButtonEl target="_blank" ref={ref} href={href} {...props} />
  }
)

ButtonEl.displayName = "ButtonEl"
Button.displayName = "Button"

export default Button
